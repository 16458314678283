import React, { Component } from "react";
import "./Testimonial.scss";

class Testimonial extends Component {
  render() {
    return (
      <section id="testemunhos" className="Testimonial">
        <div className="u-section Testimonial-content">
          <blockquote className="Testimonial-quote">
            “Foi sem dúvida a melhor escolha que poderíamos ter feito para animação infantil no nosso casamento!
            Tínhamos muitas crianças presentes e todas adoraram a animação e o insuflável, assim como os pais, que continuam a elogiar até hoje o serviço que escolhemos para animar os mais novos!
            Foi mesmo 5estrelas! Superou todas as expectativas!!
            Aconselhamos totalmente.”
          </blockquote>
          <div>
            Casamento C & F
          </div>
        </div>
      </section>
    );
  }
}

export default Testimonial;
