import React, { Component } from "react";
import { Link as AnchorLink } from "react-scroll";
import logo from "./images/logo@2x.png";
import { ReactComponent as CloseIcon } from "./icons/close.svg";
import { ReactComponent as HamburguerIcon } from "./icons/hamburguer.svg";
import { ReactComponent as FacebookIcon } from "./icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "./icons/instagram.svg";
import { ReactComponent as YoutubeIcon } from "./icons/youtube.svg";
import { ReactComponent as CasamentosIcon } from "./icons/casamentos.svg";
import "./Nav.scss";

class Nav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  handleClick = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  closeMenu = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { isOpen } = this.state;
    const offset = -60;

    return (
      <nav className={`Nav${isOpen ? " is-open" : ""}`}>
        <AnchorLink
          to="ola"
          href="#ola"
          offset={offset}
          smooth
          spy
          onClick={this.closeMenu}
        >
          <img
            src={logo}
            draggable="false"
            alt="Reguilas logo"
            className="Nav-logo"
          />
        </AnchorLink>

        <ul className="Nav-content u-section">
          <li>
            <AnchorLink
              to="servicos"
              href="#servicos"
              className="Nav-item"
              activeClass="is-active"
              offset={offset}
              smooth
              spy
              onClick={this.closeMenu}
            >
              Serviços
            </AnchorLink>
          </li>
          <li>
            <AnchorLink
              to="pacotes-de-festas"
              href="#pacotes-de-festas"
              className="Nav-item"
              activeClass="is-active"
              offset={offset}
              smooth
              spy
              onClick={this.closeMenu}
            >
              Pacotes de Festas
            </AnchorLink>
          </li>
          <li>
            <AnchorLink
              to="mascotes"
              href="#mascotes"
              className="Nav-item"
              activeClass="is-active"
              offset={offset}
              smooth
              spy
              onClick={this.closeMenu}
            >
              Mascotes
            </AnchorLink>
          </li>
          <li>
            <AnchorLink
              to="videos"
              href="#videos"
              className="Nav-item"
              activeClass="is-active"
              offset={offset}
              smooth
              spy
              onClick={this.closeMenu}
            >
              Vídeos
            </AnchorLink>
          </li>
          <li>
            <AnchorLink
              to="galeria"
              href="#galeria"
              className="Nav-item"
              activeClass="is-active"
              offset={offset}
              smooth
              spy
              onClick={this.closeMenu}
            >
              Galeria
            </AnchorLink>
          </li>
          <li>
            <AnchorLink
              to="contactos"
              href="#contactos"
              className="Nav-cta Nav-item"
              activeClass="is-active"
              offset={offset}
              smooth
              spy
              onClick={this.closeMenu}
            >
              Preços
            </AnchorLink>
          </li>
          <li className="Nav-social">
            <a
              href="https://instagram.com/_reguilas"
              target="_blank"
              rel="noopener noreferrer"
              className="Nav-social-link"
            >
              <InstagramIcon width="16" />
            </a>
            <a
              href="https://facebook.com/reguilas"
              target="_blank"
              rel="noopener noreferrer"
              className="Nav-social-link"
            >
              <FacebookIcon width="16" />
            </a>
            <a
              href="https://youtube.com/channel/UCbtDVSrIHDRPzrbmgCmtz4A"
              target="_blank"
              rel="noopener noreferrer"
              className="Nav-social-link"
            >
              <YoutubeIcon width="16" />
            </a>
            <a
              href="https://www.casamentos.pt/animacao-casamentos/reguilas-animacao-infantil--e129669"
              target="_blank"
              rel="noopener noreferrer"
              className="Nav-social-link"
              style={{ paddingLeft: 7 }}
            >
              <CasamentosIcon width="16" />
            </a>
          </li>
        </ul>

        <button
          className="Nav-button"
          onClick={this.handleClick}
          aria-label={isOpen ? "Fechar menu" : "Menu"}
        >
          {isOpen ? <CloseIcon /> : <HamburguerIcon />}
        </button>
      </nav>
    );
  }
}

export default Nav;
