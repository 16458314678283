import React, { Component } from "react";
import "./Services.scss";

import insuflaveis from "./images/servicos/insuflaveis@2x.jpg";
import decoracoes from "./images/servicos/decoracoes@2x.jpg";
import modelagemDeBaloes from "./images/servicos/modelagem-de-baloes@2x.jpg";
import pinhatas from "./images/servicos/pinhatas@2x.jpg";
import jogos from "./images/servicos/jogos@2x.jpg";
import pinturasFaciais from "./images/servicos/pinturas-faciais@2x.jpg";
import atelierDeMaquilhagem from "./images/servicos/atelier-de-maquilhagem@2x.jpg";
import mascotes from "./images/servicos/mascotes@2x.jpg";
import convites from "./images/servicos/convites@2x.jpg";
import fotografias from "./images/servicos/fotografias@2x.jpg";
import babySitting from "./images/servicos/baby-sitting@2x.jpg";
import video from "./images/servicos/video@2x.jpg";

class Services extends Component {
  render() {
    return (
      <section id="servicos" className="Services">
        <div className="u-section">
          <h2 className="Services-title u-subtitle">Serviços</h2>

          <ul>
            <li style={{ backgroundImage: `url(${insuflaveis})` }}>
              <span className="Services-name">Insufláveis</span>
            </li>
            <li style={{ backgroundImage: `url(${pinturasFaciais})` }}>
              <span className="Services-name">Pinturas Faciais</span>
            </li>
            <li style={{ backgroundImage: `url(${modelagemDeBaloes})` }}>
              <span className="Services-name">Modelagem de Balões</span>
            </li>
            <li style={{ backgroundImage: `url(${pinhatas})` }}>
              <span className="Services-name">Pinhatas</span>
            </li>
            <li style={{ backgroundImage: `url(${babySitting})` }}>
              <span className="Services-name">BabySitting</span>
            </li>
            <li style={{ backgroundImage: `url(${jogos})` }}>
              <span className="Services-name">Jogos</span>
            </li>
            <li style={{ backgroundImage: `url(${fotografias})` }}>
              <span className="Services-name">Fotografias e Vídeo</span>
            </li>
            <li style={{ backgroundImage: `url(${mascotes})` }}>
              <span className="Services-name">Mascotes</span>
            </li>
            <li style={{ backgroundImage: `url(${convites})` }}>
              <span className="Services-name">Convites</span>
            </li>
          </ul>
        </div>
      </section>
    );
  }
}

export default Services;
