import React, { Component } from "react";
import Nav from "./Nav";
import Hero from "./Hero";
import Services from "./Services";
import Plans from "./Plans";
import Mascots from "./Mascots";
import Videos from "./Videos";
import Images from "./Images";
import Contact from "./Contact";
import Testimonial from "./Testimonial";
import "./App.scss";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Nav />
        <Hero />
        <Services />
        <Plans />
        <Mascots />
        <Videos />
        <Images />
        <Contact />
        <Testimonial />
      </div>
    );
  }
}

export default App;
