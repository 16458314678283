import React, { Component } from "react";
import ModalVideo from "react-modal-video";
import "./Videos.scss";
import "react-modal-video/scss/modal-video.scss";

import image1 from "./images/videos/videos-1@2x.jpg";
import image2 from "./images/videos/videos-2@2x.jpg";
import image3 from "./images/videos/videos-3@2x.jpg";
import image4 from "./images/videos/videos-4@2x.jpg";
import { ReactComponent as PlayIcon } from "./icons/play.svg";

class Videos extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  handleOpen = videoId => {
    this.setState({ videoId, isOpen: true });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    return (
      <>
        <section id="videos" className="Videos">
          <div className="u-section">
            <h2 className="Videos-title u-subtitle">Vídeos</h2>

            <ul className="Videos-list">
              <li
                className="Videos-entry"
                style={{ backgroundImage: `url(${image1})` }}
                onClick={() => this.handleOpen("379023714")}
              >
                <div className="Videos-entry-content">
                  <div className="Videos-name">7º Aniversário do Gui!</div>
                  <div className="Videos-description">
                    Assim foi a Festinha do 7º Aniversário do Gui!
                  </div>
                </div>
                <PlayIcon />
              </li>
              <li
                className="Videos-entry"
                style={{ backgroundImage: `url(${image2})` }}
                onClick={() => this.handleOpen("387208175")}
              >
                <div className="Videos-entry-content">
                  <div className="Videos-name">3º Aniversário da I!</div>
                  <div className="Videos-description">
                    Pelo 2º Ano Consecutivo no Aniversário da I!
                  </div>
                </div>
                <PlayIcon />
              </li>
              <li
                className="Videos-entry"
                style={{ backgroundImage: `url(${image3})` }}
                onClick={() => this.handleOpen("379080403")}
              >
                <div className="Videos-entry-content">
                  <div className="Videos-name">4º Aniversário da R!</div>
                  <div className="Videos-description">
                    Aniversário da R, nas Caves de Coimbra!
                  </div>
                </div>
                <PlayIcon />
              </li>
              <li
                className="Videos-entry"
                style={{ backgroundImage: `url(${image4})` }}
                onClick={() => this.handleOpen("387208817")}
              >
                <div className="Videos-entry-content">
                  <div className="Videos-name">Uma Carta Para Vocês!</div>
                  <div className="Videos-description">
                    Uma Carta Para Vocês. Feliz Natal!
                  </div>
                </div>
                <PlayIcon />
              </li>
            </ul>
          </div>
        </section>

        <ModalVideo
          channel="vimeo"
          isOpen={this.state.isOpen}
          videoId={this.state.videoId}
          onClose={this.handleClose}
        />
      </>
    );
  }
}

export default Videos;
