import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Images.scss";

import image1size720 from "./images/galeria/1@720w.jpg";
import image1size960 from "./images/galeria/1@960w.jpg";
import image1size1024 from "./images/galeria/1@1024w.jpg";
import image1size1280 from "./images/galeria/1@1280w.jpg";
import image1size1920 from "./images/galeria/1@1920w.jpg";

import image2size720 from "./images/galeria/2@720w.jpg";
import image2size960 from "./images/galeria/2@960w.jpg";
import image2size1024 from "./images/galeria/2@1024w.jpg";
import image2size1280 from "./images/galeria/2@1280w.jpg";
import image2size1920 from "./images/galeria/2@1920w.jpg";

import image3size720 from "./images/galeria/3@720w.jpg";
import image3size960 from "./images/galeria/3@960w.jpg";
import image3size1024 from "./images/galeria/3@1024w.jpg";
import image3size1280 from "./images/galeria/3@1280w.jpg";
import image3size1920 from "./images/galeria/3@1920w.jpg";

import image4size720 from "./images/galeria/4@720w.jpg";
import image4size960 from "./images/galeria/4@960w.jpg";
import image4size1024 from "./images/galeria/4@1024w.jpg";
import image4size1280 from "./images/galeria/4@1280w.jpg";
import image4size1920 from "./images/galeria/4@1920w.jpg";

import image5size720 from "./images/galeria/5@720w.jpg";
import image5size960 from "./images/galeria/5@960w.jpg";
import image5size1024 from "./images/galeria/5@1024w.jpg";
import image5size1280 from "./images/galeria/5@1280w.jpg";
import image5size1920 from "./images/galeria/5@1920w.jpg";

import image6size720 from "./images/galeria/6@720w.jpg";
import image6size960 from "./images/galeria/6@960w.jpg";
import image6size1024 from "./images/galeria/6@1024w.jpg";
import image6size1280 from "./images/galeria/6@1280w.jpg";
import image6size1920 from "./images/galeria/6@1920w.jpg";

import image7size720 from "./images/galeria/7@720w.jpg";
import image7size960 from "./images/galeria/7@960w.jpg";
import image7size1024 from "./images/galeria/7@1024w.jpg";
import image7size1280 from "./images/galeria/7@1280w.jpg";
import image7size1920 from "./images/galeria/7@1920w.jpg";

import image8size720 from "./images/galeria/8@720w.jpg";
import image8size960 from "./images/galeria/8@960w.jpg";
import image8size1024 from "./images/galeria/8@1024w.jpg";
import image8size1280 from "./images/galeria/8@1280w.jpg";
import image8size1920 from "./images/galeria/8@1920w.jpg";

import image9size720 from "./images/galeria/9@720w.jpg";
import image9size960 from "./images/galeria/9@960w.jpg";
import image9size1024 from "./images/galeria/9@1024w.jpg";
import image9size1280 from "./images/galeria/9@1280w.jpg";
import image9size1920 from "./images/galeria/9@1920w.jpg";

import image10size720 from "./images/galeria/10@720w.jpg";
import image10size960 from "./images/galeria/10@960w.jpg";
import image10size1024 from "./images/galeria/10@1024w.jpg";
import image10size1280 from "./images/galeria/10@1280w.jpg";
import image10size1920 from "./images/galeria/10@1920w.jpg";

import image11size720 from "./images/galeria/11@720w.jpg";
import image11size960 from "./images/galeria/11@960w.jpg";
import image11size1024 from "./images/galeria/11@1024w.jpg";
import image11size1280 from "./images/galeria/11@1280w.jpg";
import image11size1920 from "./images/galeria/11@1920w.jpg";

import image12size720 from "./images/galeria/12@720w.jpg";
import image12size960 from "./images/galeria/12@960w.jpg";
import image12size1024 from "./images/galeria/12@1024w.jpg";
import image12size1280 from "./images/galeria/12@1280w.jpg";
import image12size1920 from "./images/galeria/12@1920w.jpg";

import image13size720 from "./images/galeria/13@720w.jpg";
import image13size960 from "./images/galeria/13@960w.jpg";
import image13size1024 from "./images/galeria/13@1024w.jpg";
import image13size1280 from "./images/galeria/13@1280w.jpg";
import image13size1920 from "./images/galeria/13@1920w.jpg";

import image14size720 from "./images/galeria/14@720w.jpg";
import image14size960 from "./images/galeria/14@960w.jpg";
import image14size1024 from "./images/galeria/14@1024w.jpg";
import image14size1280 from "./images/galeria/14@1280w.jpg";
import image14size1920 from "./images/galeria/14@1920w.jpg";

import image15size720 from "./images/galeria/15@720w.jpg";
import image15size960 from "./images/galeria/15@960w.jpg";
import image15size1024 from "./images/galeria/15@1024w.jpg";
import image15size1280 from "./images/galeria/15@1280w.jpg";
import image15size1920 from "./images/galeria/15@1920w.jpg";

import image16size720 from "./images/galeria/16@720w.jpg";
import image16size960 from "./images/galeria/16@960w.jpg";
import image16size1024 from "./images/galeria/16@1024w.jpg";
import image16size1280 from "./images/galeria/16@1280w.jpg";
import image16size1920 from "./images/galeria/16@1920w.jpg";

import image17size720 from "./images/galeria/17@720w.jpg";
import image17size960 from "./images/galeria/17@960w.jpg";
import image17size1024 from "./images/galeria/17@1024w.jpg";
import image17size1280 from "./images/galeria/17@1280w.jpg";
import image17size1920 from "./images/galeria/17@1920w.jpg";

class Images extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  handleOpen = videoId => {
    this.setState({ videoId, isOpen: true });
  };

  handleClose = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true
    };
    const images = [
      {
        id: "image1",
        url: {
          "720": image1size720,
          "960": image1size960,
          "1024": image1size1024,
          "1280": image1size1280,
          "1920": image1size1920
        }
      },
      {
        id: "image2",
        url: {
          "720": image2size720,
          "960": image2size960,
          "1024": image2size1024,
          "1280": image2size1280,
          "1920": image2size1920
        }
      },
      {
        id: "image3",
        url: {
          "720": image3size720,
          "960": image3size960,
          "1024": image3size1024,
          "1280": image3size1280,
          "1920": image3size1920
        }
      },
      {
        id: "image4",
        url: {
          "720": image4size720,
          "960": image4size960,
          "1024": image4size1024,
          "1280": image4size1280,
          "1920": image4size1920
        }
      },
      {
        id: "image5",
        url: {
          "720": image5size720,
          "960": image5size960,
          "1024": image5size1024,
          "1280": image5size1280,
          "1920": image5size1920
        }
      },
      {
        id: "image6",
        url: {
          "720": image6size720,
          "960": image6size960,
          "1024": image6size1024,
          "1280": image6size1280,
          "1920": image6size1920
        }
      },
      {
        id: "image7",
        url: {
          "720": image7size720,
          "960": image7size960,
          "1024": image7size1024,
          "1280": image7size1280,
          "1920": image7size1920
        }
      },
      {
        id: "image8",
        url: {
          "720": image8size720,
          "960": image8size960,
          "1024": image8size1024,
          "1280": image8size1280,
          "1920": image8size1920
        }
      },
      {
        id: "image9",
        url: {
          "720": image9size720,
          "960": image9size960,
          "1024": image9size1024,
          "1280": image9size1280,
          "1920": image9size1920
        }
      },
      {
        id: "image10",
        url: {
          "720": image10size720,
          "960": image10size960,
          "1024": image10size1024,
          "1280": image10size1280,
          "1920": image10size1920
        }
      },
      {
        id: "image11",
        url: {
          "720": image11size720,
          "960": image11size960,
          "1024": image11size1024,
          "1280": image11size1280,
          "1920": image11size1920
        }
      },
      {
        id: "image12",
        url: {
          "720": image12size720,
          "960": image12size960,
          "1024": image12size1024,
          "1280": image12size1280,
          "1920": image12size1920
        }
      },
      {
        id: "image13",
        url: {
          "720": image13size720,
          "960": image13size960,
          "1024": image13size1024,
          "1280": image13size1280,
          "1920": image13size1920
        }
      },
      {
        id: "image14",
        url: {
          "720": image14size720,
          "960": image14size960,
          "1024": image14size1024,
          "1280": image14size1280,
          "1920": image14size1920
        }
      },
      {
        id: "image15",
        url: {
          "720": image15size720,
          "960": image15size960,
          "1024": image15size1024,
          "1280": image15size1280,
          "1920": image15size1920
        }
      },
      {
        id: "image16",
        url: {
          "720": image16size720,
          "960": image16size960,
          "1024": image16size1024,
          "1280": image16size1280,
          "1920": image16size1920
        }
      },
      {
        id: "image17",
        url: {
          "720": image17size720,
          "960": image17size960,
          "1024": image17size1024,
          "1280": image17size1280,
          "1920": image17size1920
        }
      }
    ];

    return (
      <section id="galeria" className="Images">
        <div className="u-section">
          <h2 className="Images-title u-subtitle">Galeria</h2>

          <Slider {...settings}>
            {images.map(image => (
              <img
                key="{image.id}"
                className="EntryCarousel-image-content"
                alt=""
                src={image.url["1024"]}
                srcSet={`${image.url["720"]} 720w,
                         ${image.url["960"]} 960w,
                         ${image.url["1024"]} 1024w,
                         ${image.url["1280"]} 1280w,
                         ${image.url["1920"]} 1920w`}
                draggable={false}
                sizes="100vw"
              />
            ))}
          </Slider>
        </div>
      </section>
    );
  }
}

export default Images;
