import React, { Component } from "react";
import "./Mascots.scss";

import pacote1 from "./images/pacotes/minnie@2x.png";
import pacote2 from "./images/pacotes/chase-patrulha-pata@2x.png";
import pacote3 from "./images/pacotes/homem-aranha@2x.png";
import pacote4 from "./images/pacotes/panda@2x.png";
import mickey from "./images/pacotes/mickey@2x.png";

import mascoteMinnie from "./images/mascotes/mascote-minnie@2x.jpg";
import mascoteChase from "./images/mascotes/mascote-chase-patrulha-pata@2x.jpg";
import mascoteHomemAranha from "./images/mascotes/mascote-homem-aranha@2x.jpg";
import mascotePanda from "./images/mascotes/mascote-panda@2x.jpg";
import mascoteMickey from "./images/mascotes/mascote-mickey@2x.jpg";

class Mascots extends Component {
  render() {
    return (
      <section id="mascotes" className="Mascots">
        <div className="u-section">
          <h2 className="Mascots-title u-subtitle">Mascotes</h2>

          <ul>
            <li
              style={{ backgroundImage: `url(${pacote1})` }}
              className="Mascots-thumb"
            />
            <li style={{ backgroundImage: `url(${mascoteMinnie})` }} />
            <li>Minnie</li>

            <li
              style={{ backgroundImage: `url(${pacote2})` }}
              className="Mascots-thumb"
            />
            <li style={{ backgroundImage: `url(${mascoteChase})` }} />
            <li>Chase</li>

            <li
              style={{ backgroundImage: `url(${pacote3})` }}
              className="Mascots-thumb"
            />
            <li style={{ backgroundImage: `url(${mascoteHomemAranha})` }} />
            <li>Homem Aranha</li>

            <li
              style={{ backgroundImage: `url(${pacote4})` }}
              className="Mascots-thumb"
            />
            <li style={{ backgroundImage: `url(${mascotePanda})` }} />
            <li>Panda</li>

            <li
              style={{ backgroundImage: `url(${mickey})` }}
              className="Mascots-thumb"
            />
            <li style={{ backgroundImage: `url(${mascoteMickey})` }} />
            <li>Mickey</li>
          </ul>
        </div>
      </section>
    );
  }
}

export default Mascots;
