import React, { Component } from "react";
import { ReactComponent as LivroReclamacoesIcon } from "./icons/livro-reclamacoes.svg";
import "./Contact.scss";

class Contact extends Component {
  render() {
    return (
      <section id="contactos" className="Contact">
        <div className="u-section Contact-content">
          <h2 className="Contact-title u-subtitle">Contactos e Preços</h2>

          <form
            className="Contact-form"
            action="https://submit-form.com/c59b3149-8329-4a43-80b7-986cf1b800d7"
          >
            <div className="Contact-field">
              <label>
                <span>Nome</span>
                <input type="text" name="nome" />
              </label>
            </div>

            <div className="Contact-field">
              <label>
                <span>Contacto</span>
                <input
                  type="text"
                  name="telefone"
                  required
                  placeholder="Email"
                />
              </label>
            </div>

            <div className="Contact-field Contact-field--small">
              <label>
                <span>Data da Festa</span>
                <input type="date" name="data-da-festa" />
              </label>
            </div>

            <div className="Contact-field Contact-field--small">
              <label>
                <span>Nº de Crianças</span>
                <input
                  type="number"
                  name="numero-criancas"
                  placeholder="Aproximado"
                />
              </label>
            </div>

            <div className="Contact-field Contact-field--small">
              <label>
                <span>Média de Idades</span>
                <input
                  type="number"
                  name="media-idades"
                  placeholder="Aproximada"
                />
              </label>
            </div>

            <div className="Contact-field">
              <label>
                <span>Localização</span>
                <input type="text" name="localizacao" rows="3" />
              </label>
            </div>

            <div className="Contact-field">
              <label>
                <span>Mensagem</span>
                <textarea name="mensagem" rows="3" />
              </label>
            </div>

            <div className="Contact-field">
              <button type="submit">Enviar</button>
            </div>

            <div className="Contact-address">
              Distrito de Coimbra
              <br />
              +351 910 997 292 (custo de chamada móvel nacional)
              <br />
              reguilaseventos@gmail.com
              <br />
              <a href="https://livroreclamacoes.pt/" target="_blank" rel="noopener noreferrer">
                <LivroReclamacoesIcon className="Contact-icon" />
              </a>
            </div>
          </form>
        </div>
      </section>
    );
  }
}

export default Contact;
