import React, { Component } from "react";
import "./Plans.scss";

import pacote1 from "./images/pacotes/minnie@2x.png";
import pacote2 from "./images/pacotes/chase-patrulha-pata@2x.png";
import pacote3 from "./images/pacotes/homem-aranha@2x.png";
import pacote4 from "./images/pacotes/panda@2x.png";

class Plans extends Component {
  render() {
    return (
      <section id="pacotes-de-festas" className="Plans">
        <div className="u-section">
          <h2 className="Plans-title u-subtitle">Pacotes de Festas</h2>

          <div className="Plans-table">
            <div className="Plans-item Plans-item--1">
              <h3>Pacote 1</h3>

              <div className="Plans-image">
                <img src={pacote1} alt="Pacote 1" />
              </div>

              <ul>
                <li className="Plans-item-feature">1 Animador</li>
                <li className="Plans-item-feature">Pinturas Faciais</li>
                <li className="Plans-item-feature">Modelagem de Balões</li>
                <li className="Plans-item-feature">Mesa com Desenhos</li>
                <li className="Plans-item-feature Plans-item-feature--small">Oferta de Convites</li>
                <li className="u-fill" />
                <li className="Plans-item-footer">2h30</li>
              </ul>
            </div>

            <div className="Plans-item Plans-item--2">
              <h3>Pacote 2</h3>

              <div className="Plans-image">
                <img src={pacote2} alt="Pacote 2" />
              </div>

              <ul>
                <li className="Plans-item-feature">2 Animadores</li>
                <li className="Plans-item-feature">Pinturas Faciais</li>
                <li className="Plans-item-feature">Modelagem de Balões</li>
                <li className="Plans-item-feature">1 Mascote</li>
                <li className="Plans-item-feature">Música</li>
                <li className="Plans-item-feature Plans-item-feature--small">Oferta de Convites</li>
                <li className="u-fill" />
                <li className="Plans-item-footer">2h30</li>
              </ul>
            </div>

            <div className="Plans-item Plans-item--3">
              <h3>Pacote 3</h3>

              <div className="Plans-image">
                <img src={pacote3} alt="Pacote 3" />
              </div>

              <ul>
                <li className="Plans-item-feature">2 Animadores</li>
                <li className="Plans-item-feature">Insuflável</li>
                <li className="Plans-item-feature">Pinturas Faciais</li>
                <li className="Plans-item-feature">Modelagem de Balões</li>
                <li className="Plans-item-feature">1 Mascote</li>
                <li className="Plans-item-feature">Música</li>
                <li className="Plans-item-feature Plans-item-feature--small">Oferta de Convites</li>
                <li className="u-fill" />
                <li className="Plans-item-footer">3h</li>
              </ul>
            </div>

            <div className="Plans-item Plans-item--4">
              <h3>Pacote 4</h3>

              <div className="Plans-image">
                <img src={pacote4} alt="Pacote 4" />
              </div>

              <ul>
                <li className="Plans-item-feature">3 Animadores</li>
                <li className="Plans-item-feature">Insuflável</li>
                <li className="Plans-item-feature">Pinturas Faciais</li>
                <li className="Plans-item-feature">Modelagem de Balões</li>
                <li className="Plans-item-feature">1 Mascote</li>
                <li className="Plans-item-feature">Música</li>
                <li className="Plans-item-feature">Pinhata</li>
                <li className="Plans-item-feature Plans-item-feature--small">Oferta de Convites</li>
                <li className="u-fill" />
                <li className="Plans-item-footer">3h30</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Plans;
