import React, { Component } from "react";
import videoMp4 from "./videos/video.mp4";
import "./Hero.scss";

class Hero extends Component {
  render() {
    return (
      <header id="ola" className="Hero">
        <div
          dangerouslySetInnerHTML={{
            __html: `<video autoplay muted playsinline class="Hero-video" src="${videoMp4}" />`
          }}
        />
        <div className="Hero-content u-section">
          <h1>REGUILAS</h1>
          <h2>Festas de Aniversário ao Domicílio</h2>
          <p>Distrito de Coimbra</p>
        </div>
      </header>
    );
  }
}

export default Hero;
